var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"elevation":"1"}},[_c('v-card-title',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.type.replace("_", " ").toLowerCase()))]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tollFreeDIDList,"options":_vm.options,"server-items-length":_vm.totalItem},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.options.page - 1) * _vm.options.itemsPerPage + (_vm.tollFreeDIDList.indexOf(item) + 1))+" ")]}},{key:"item.prefix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prefix)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.didType == 'LOCAL_DID')?_c('span',[_vm._v("LOCAL")]):_vm._e(),(item.didType == 'NATIONAL_DID')?_c('span',[_vm._v("NATIONAL")]):_vm._e(),(item.didType == 'MOBILE_DID')?_c('span',[_vm._v("MOBILE")]):_vm._e(),(item.didType == 'VIRTUAL_PRI_DID')?_c('span',[_vm._v("VIRTUAL PRI")]):_vm._e(),(item.didType == 'TOLL_FREE')?_c('span',[_vm._v("TOLL FREE")]):_vm._e(),(item.didType == 'SHARED_COST')?_c('span',[_vm._v("SHARED COST")]):_vm._e()]}},{key:"item.endUserRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requireDocument == "Y" ? "Required" : item.wellKnownText ? "Required" : "Not Required")+" "),(item.wellKnownText)?_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" info ")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.wellKnownText)}})]):_vm._e()]}},{key:"item.voiceIn",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({style:(_vm.getAvatarStyle(item)),attrs:{"size":"20"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTooltipText(item)))])])]}},{key:"item.t38Fax",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.t38Fax === 'Y')?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"success","size":"20"}})],1):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"grey","size":"20"}})],1)]}}],null,true)},[(item.t38Fax === 'Y')?_c('span',[_vm._v("T.38/Fax support")]):_c('span',[_vm._v("T.38/Fax not supported")])])]}},{key:"item.smsIn",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.smsIn === 'Y')?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"success","size":"20"}})],1):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"grey","size":"20"}})],1)]}}],null,true)},[(item.smsIn === 'Y')?_c('span',[_vm._v("SMS In support")]):_c('span',[_vm._v("SMS IN not supported")])])]}},{key:"item.smsOutP2P",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({style:(_vm.getAvatarStyleForSMS(item)),attrs:{"size":"20"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTooltipTextForSMS(item)))])])]}},{key:"item.emergency",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.emergency === 'Y')?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"success","size":"20"}})],1):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"grey","size":"20"}})],1)]}}],null,true)},[(item.emergency === 'Y')?_c('span',[_vm._v("Emergency In support")]):_c('span',[_vm._v("Emergency not supported")])])]}},{key:"item.cnamOut",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.cnamOut === 'Y')?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"success","size":"20"}})],1):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{attrs:{"color":"grey","size":"20"}})],1)]}}],null,true)},[(item.cnamOut === 'Y')?_c('span',[_vm._v("CNAM OUT In support")]):_c('span',[_vm._v("CNAM OUT not supported")])])]}},{key:"item.noOfChannels",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.noOfChannels)+" ")]}},{key:"item.perMinCharge",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.perMinChargeFixed)+" "),_c('v-tooltip',{staticClass:"px-0 mx-0",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" info ")])],1)]}}],null,true)},[_c('table',{staticClass:"white black--text",attrs:{"id":"myTable"}},[_c('tr',[_c('th',[_vm._v("Source")]),_c('th',[_vm._v("Cost")])]),_c('tr',[_c('td',[_vm._v("Fixed")]),_c('td',[_vm._v("$ "+_vm._s(item.perMinChargeFixed))])]),_c('tr',[_c('td',[_vm._v("Mobile")]),_c('td',[_vm._v("$ "+_vm._s(item.perMinChargeMobile))])]),_c('tr',[_c('td',[_vm._v("Payphone")]),_c('td',[_vm._v("$ "+_vm._s(item.perMinChargePayphone))])])])])]}},{key:"item.setupCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.setupCost)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.price)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-3",attrs:{"type":"number","min":"1","autocomplete":"off","filled":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":!item.quantity,"color":"black"},on:{"click":function($event){return _vm.addToCart(item)}}},[_vm._v("shopping_cart")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }